<template>
  <v-container
    class="pa-0"
    :style="'background: ' + sectionBgColor"
    fill-height
    fluid
  >
    <v-overlay v-model="countdown">
      <div style="position: relative">
        <div style="position: absolute; top: -40vh; transform: translate(-50%)">
          <h1 style="font-size: 300px">
            {{ countdown_timer }}
          </h1>
        </div>
      </div>
    </v-overlay>
    <v-overlay v-model="loadScreen">
      <v-progress-circular
        indeterminate
        color="primary"
        size="40"
      ></v-progress-circular>
    </v-overlay>
    <v-row
      :style="!recordStarted ? '' : 'position: absolute; opacity: 0'"
      justify="center"
    >
      <v-col v-if="!startBtn && firstPage" cols="12" md="6">
        <v-card
          class="rounded-xl pa-10 mt-10"
          :style="'background: ' + brandColor"
          flat
        >
          <div class="center-logo">
            <router-link to="/">
              <img
                :key="app_domain_logo_key"
                :src="app_domain_logo_url"
                alt="Recording App Logo"
                class="logo-size"
              />
            </router-link>
          </div>
          <div class="recording-title">
            <strong :style="'color: ' + headingTextColor">
              {{ receiver_name_email }}
              has requested you to Record a Video
            </strong>
          </div>
          <div class="mt-3">
            <v-row justify="center" class="">
              <v-switch
                :disabled="screenAndCam || toggleScreen"
                flat
                inset
                v-model="toggleCamera"
                label="Camera"
              ></v-switch>
            </v-row>
            <v-row justify="center" class="mt-n6 ml-n5 d-none d-md-flex">
              <v-switch
                :disabled="toggleCamera || screenAndCam"
                flat
                inset
                v-model="toggleScreen"
                label="Screen"
              ></v-switch>
            </v-row>
            <v-row
              v-if="$vuetify.breakpoint.mdAndUp"
              justify="center"
              class="mt-n6"
              style="padding-left: 43px"
            >
              <v-switch
                :disabled="toggleCamera || toggleScreen"
                flat
                inset
                v-model="screenAndCam"
              >
                <template v-slot:label>
                  <span>Screen + Cam </span>
                  <small
                    style="position: absolute; min-width: 200px; left: 60px"
                    v-if="toggleScreen && firefox"
                  >
                    (Not Supported on Firefox)
                  </small>
                </template>
              </v-switch>
            </v-row>
            <v-row justify="center">
              <screen-resolution
                :selected="video_quality"
                :disabled="record_btn === 'Stop Recording'"
              ></screen-resolution>
            </v-row>
            <v-row justify="center" class="my-0">
              <v-btn
                :disabled="record_btn === 'Stop Recording'"
                text
                class="secondary text-capitalize px-2 custom-mic"
                @click="toggleMic = !toggleMic"
              >
                <v-icon size="17px" color="black" class="mr-1">
                  mdi-microphone
                </v-icon>
                <small class="mic-chip">
                  Default - Microphone
                  <v-chip label x-small :class="microphoneSwitchClasses">
                    {{ toggleMic ? "on" : "off" }}
                  </v-chip>
                </small>
              </v-btn>
            </v-row>
          </div>
          <div class="d-flex justify-center mt-4">
            <v-btn
              :id="record_btn !== 'Stop Recording' ? 'captureBtn' : ''"
              @click="
                record_btn !== 'Stop Recording'
                  ? startRecording()
                  : stopRecording()
              "
              class="white--text rounded-xl capitalize px-10 custom-start-btn"
              large
              :color="accentColor"
              :style="'color: ' + buttonTextColor"
              :disabled="!toggleScreen && !toggleCamera && !screenAndCam"
            >
              <v-icon v-if="record_btn === 'Stop Recording'" class="blink">
                mdi-square
              </v-icon>
              {{ record_btn }}
            </v-btn>
          </div>
          <div class="mt-10">
            <strong class="capitalize error--text">protect your privacy</strong>
            <small class="d-block" :style="'color: ' + headingTextColor">
              Before you start recording, you'll be prompted which screen or
              window you would like to record. You can preview your recording on
              this page before you decide to upload it.
            </small>
          </div>
        </v-card>
      </v-col>
      <v-col
        v-if="startBtn"
        cols="12"
        md="6"
        style="position: absolute; top: 10px"
      >
        <v-card class="white py-4 px-7 rounded-xl">
          <div class="center-logo">
            <app-logo></app-logo>
          </div>
          <div class="mt-4">
            <strong class="capitalize text-h5">
              Recording will start once you allow access to your screen...
            </strong>
            <small class="d-block grey--text mt-6">
              Please follow your browser instructions
            </small>
          </div>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="6"
        :class="!shareScreen ? 'showScreen' : 'hideScreen'"
      >
        <video-js :settings="settings" :mic="toggleMic"></video-js>
      </v-col>
    </v-row>
    <v-row
      class="mt-4"
      justify="center"
      :style="
        !toggleScreen && recordStarted && toggleCamera
          ? ''
          : 'opacity: 0; position: absolute; z-index: -9999'
      "
    >
      <v-col cols="12">
        <v-btn
          @click="stopRecording"
          class="rounded-xl capitalize px-10 float-right mr-16 white--text"
          large
          :color="accentColor"
        >
          <v-icon v-if="record_btn === 'Stop Recording'" class="blink">
            mdi-square
          </v-icon>
          {{ record_btn }}
        </v-btn>
      </v-col>
      <v-col cols="10">
        <div>
          <video
            :width="
              screenClass
                ? $vuetify.breakpoint.smAndDown
                  ? '100%'
                  : ''
                : '100%'
            "
            playsinline
            id="webCamera"
            :style="toggleScreen ? screenClass : ''"
            controls
          ></video>
        </div>
      </v-col>
    </v-row>
    <button style="visibility: hidden" id="camBtn">Button</button>
  </v-container>
</template>

<script>
// import DomainLogo from "../../components/Domains/DomainLogo";
import VideoJs from "../../components/VideoJs";
import ScreenResolution from "../../components/ScreenResolution";
import { mapActions, mapMutations, mapGetters } from "vuex";
import ResponseHelper from "../../utils/ResponseHelper";
export default {
  components: {
    // DomainLogo,
    VideoJs,
    ScreenResolution,
  },
  name: "SendScreenRecording",
  data() {
    return {
      toggleMic: true,
      toggleScreen: false,
      toggleCamera: false,
      screenAndCam: false,
      firstPage: true,
      startBtn: false,
      shareScreen: false,
      finished: false,
      showScreen: {
        opacity: 0,
        position: "absolute",
      },
      loadScreen: false,
      toast: new ResponseHelper(),
      record_btn: "start recording",
      disabled: false,
      screenClass:
        "pointer-events: none; opacity: 0; position: absolute; left: 0",
      recordStarted: false,
      firefox: navigator.userAgent.includes("Firefox"),
      logo: null,
      video_quality: 5000000,
      startCountDown: false,
      countdown: false,
      countdown_timer: 3,
      countdown_id: null,
      receiver_name_email: null,
      brandColor: null,
      accentColor: null,
      sectionBgColor: null,
      headingTextColor: null,
      buttonTextColor: null,
      app_domain_logo_key: 0,
      app_domain_logo_url: require("../../assets/logo.png"),
    };
  },
  created() {
    if (this.user_details) {
      this.logo = this.user_details?.settings?.logo_url;
    }
    this.getUser();
    this.$root.$on("video-quality", (data) => {
      this.video_quality = data;
    });
  },
  mounted() {
    this.countdown_id = setInterval(() => {
      if (this.countdown && this.countdown_timer > 0) {
        this.countdown_timer -= 1;
        if (this.countdown_timer <= 0) {
          this.countdown = false;
          clearInterval(this.countdown_id);
        }
      }
    }, 1200);
  },
  methods: {
    ...mapActions("recordings", ["get_user"]),
    ...mapMutations("recordings", ["USER_DETAILS"]),
    goBack() {
      this.$router.go(-1);
    },
    startRecording() {
      this.startBtn = true;
      this.firstPage = false;
      this.shareScreen = true;
    },
    stopRecording() {
      this.$root.$emit("stop-recording");
    },
    clickCamBtn() {
      document.getElementById("camBtn").click();
    },
    async getUser() {
      this.loadScreen = true;
      const request_link =
        window.location.href.indexOf(process.env.VUE_APP_URL) === -1
          ? window.location.hostname
          : this.$route.params.personal_url;
      try {
        const response = await this.get_user(request_link);
        if (
          !response.data.user ||
          Object.keys(response.data.user).length === 0
        ) {
          await this.$router.push({ name: "Dashboard" });
          this.$toast.error(
            "This link does not exist, might have been deleted!"
          );
        }
        if (response.data.settings) {
          this.logo =
            response?.data?.logo_url || response?.data?.settings?.logo_url;
        }

        var defaultDomain = null;

        // check if is category response
        if (response.data.record_link) {
          let domainsDtls = response.data.user.custom_domains;

          defaultDomain = domainsDtls.find(
            (domain) => domain.default === "yes"
          );
          console.log("Category response"); // response.data.user.custom_domains
        }

        // check if is domain response
        else if (response.data.domain_logo_url) {
          defaultDomain = response.data;
          console.log("Domain response"); // response.data
        }

        // if none of the above
        else {
          console.log("Something is wrong while fetching use details.");
        }

        // collect receiver details
        this.receiver_name_email =
          response.data.user.name ?? response.data.user.email;

        if (defaultDomain !== null) {
          this.app_domain_logo_url = defaultDomain.domain_logo_url;
          this.app_domain_logo_key += 1;

          localStorage.setItem("favicon", defaultDomain.favicon_url);
          localStorage.setItem("tagline", defaultDomain.tagline);
          localStorage.setItem("domain_logo", defaultDomain.domain_logo_url);
          let checkDefault = defaultDomain.tagline !== undefined ? true : false;
          localStorage.setItem("is_custom_domain", checkDefault);
          console.log("Is Custom Domain");
        } else {
          localStorage.removeItem("favicon");
          localStorage.removeItem("tagline");
          localStorage.removeItem("domain_logo");
          localStorage.setItem("is_custom_domain", false);
          console.log("Custom Domain Not Found!");
        }

        this.brandColor = defaultDomain.branding.brand_color || "#FFFFFF";
        this.accentColor = defaultDomain.branding.accent_color || "primary";

        this.sectionBgColor =
          defaultDomain.branding.section_bg_color || "#5A67FB";
        this.headingTextColor =
          defaultDomain.branding.heading_text_color || "#000000";
        this.buttonTextColor =
          defaultDomain.branding.button_text_color || "#FFFFFF";

        // this.$store.commit("settings/SETTINGS", response.data.settings);
        this.loadScreen = false;
      } catch (e) {
        this.loadScreen = false;
        this.toast.sendError(e);
      }
    },
  },
  computed: {
    ...mapGetters("recordings", ["user_details"]),
    // nameEmail() {
    //   return this.user_details?.user?.name || this.user_details?.user?.email;
    // },
    // brandColor() {
    //   return this.user_details?.settings?.brand_color || "#ffffff";
    // },
    // accentColor() {
    //   return this.user_details?.settings?.accent_color;
    // },
    settings() {
      return this.user_details?.settings || {};
    },
    microphoneSwitchClasses() {
      return {
        primary: this.toggleMic,
        red: !this.toggleMic,
        "px-1": true,
        "switch-on-color": this.toggleMic,
        "switch-off-color": !this.toggleMic,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.showScreen {
  opacity: 0;
  position: absolute;
  z-index: -999;
}
.hideScreen {
  opacity: 1;
  z-index: 1;
}
.blink {
  animation: fadeinout 1.5s infinite;
}
@keyframes fadeinout {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.custom-mic {
  border: 1px solid #eee !important;
  width: 250px !important;
  height: 40px !important;
}
.custom-start-btn {
  width: 270px !important;
  height: 60px !important;
  border-radius: 50px !important;
  font-size: 17px !important;
}
.switch-off-color {
  color: #fff !important;
}
.switch-on-color {
  color: #fff !important;
}
.mic-chip {
  color: #000;
}
.recording-title {
  // font-size: 24px !important;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50 !important;
}
.logo-size {
  height: 50px;
  width: 150px;
  margin-bottom: 20px;
}
</style>
