<template>
  <div style="margin-top: 7vh">
    <v-card v-show="!completed" class="pa-10 rounded-xl" flat>
      <div class="center-logo">
        <app-logo :logo="logo"></app-logo>
      </div>
      <div class="d-flex justify-center">
        <strong>Uploading</strong>
      </div>
      <div class="mt-5">
        <v-progress-linear
          height="15"
          color="primary"
          class="rounded-xl"
          v-model="uploadedAmount"
        ></v-progress-linear>
      </div>
      <span v-show="isUploading" class="mt-4 d-block">
        <button @click="isPaused ? resume() : pause()">
          <v-icon title="Pause" v-if="!isPaused" color="primary">
            mdi-pause-circle-outline
          </v-icon>
          <v-icon title="Resume" v-if="isPaused" color="primary">
            mdi-play-circle-outline
          </v-icon>
        </button>
        <v-icon title="Cancel" class="ml-4" @click="cancel" color="error">
          mdi-close-circle-outline
        </v-icon>
      </span>
    </v-card>
    <upload-completed
      :settings="settings"
      v-show="completed"
    ></upload-completed>
  </div>
</template>

<script>
import AppLogo from "./Logo";
import UploadCompleted from "./UploadCompleted";
export default {
  components: {
    AppLogo,
    UploadCompleted,
  },
  props: ["file", "status", "progress", "settings"],
  name: "UploadProgress",
  data() {
    return {
      completed: false,
      isPaused: false,
    };
  },
  watch: {
    status(val) {
      if (val === "success") {
        this.completed = true;
        this.$root.$emit("upload-completed");
        // this.$store.commit("recordings/USER_DETAILS", {});
      }
    },
  },
  computed: {
    isUploading() {
      return this.status !== "success" && this.status !== "canceled";
    },
    uploadedAmount() {
      return (this.progress * 100).toFixed(2);
    },
    logo() {
      return this.settings?.logo_url || null;
    },
  },
  methods: {
    upload() {
      this.file.resumableObj.upload();
      this.isPaused = false;
    },
    pause() {
      this.file.pause();
      this.isPaused = true;
    },
    resume() {
      this.pause();
      this.upload();
    },
    cancel() {
      this.$emit("cancel", this.file);
      this.$parent.goBack();
    },
  },
};
</script>

<style scoped></style>
