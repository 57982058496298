<template>
  <div class="custom-box">
    <label for="" class="label">Select video resolution</label>
    <v-col class="d-flex left-aligned-menu" cols="12" sm="12">
      <v-select
        @change="updateVideoQuality"
        :disabled="disabled"
        v-model="video_quality"
        background-color="secondary"
        :items="video_quality_options"
        item-text="text"
        item-value="value"
        class="custom-select-box"
        style="width: 84%; margin: 0 auto"
        dense
        outlined
        eager
      ></v-select>
    </v-col>
  </div>
</template>

<script>
export default {
  name: "ScreenResolution",
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      video_quality: this.selected,
      video_quality_options: [
        { text: "SD (Standard Defination) 360p", value: 1000000 },
        { text: "FSD (Full Standard Defination) 480p", value: 2500000 },
        { text: "HD (High Defination) 7200p", value: 5000000 },
        { text: "FHD (Full High Defination) 1080p", value: 8000000 },
        { text: "2k Video (Quad HD)", value: 16000000 },
        { text: "4k Video or Ultra HD (UHD)", value: 40000000 },
      ],
    };
  },

  methods: {
    updateVideoQuality() {
      this.$root.$emit("video-quality", this.video_quality);
    },
  },
};
</script>

<style scoped>
.label {
  font-size: 11px !important;
  color: grey !important;
}
.custom-select-box {
  font-size: 11px !important;
}
.custom-box {
  text-align: left !important;
  width: 250px !important;
}
.left-aligned-menu {
  text-align: left !important;
  padding: 0px;
}
</style>
